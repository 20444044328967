import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { A11yStatusService } from '../../helper/services/a11ystatus.service';

@Component({
  standalone: true,
  selector: 'ig-a11y-status',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<div aria-live="polite" role="status" style="visiblity: hidden; position: absolute; width: 0px; height: 0px; overflow: hidden;">{{ a11yStatusService.status() }}</div>`
})
export class A11yStatusComponent {
  readonly a11yStatusService = inject(A11yStatusService);




}
