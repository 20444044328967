import { Injectable } from '@angular/core';
import { RouterStateSnapshot, Routes, TitleStrategy } from '@angular/router';
import { NoAuthGuard } from './@shared/guards/no-auth.guard';
import { AuthGuard } from './@shared/guards/auth-guard.guard';
import { NotFoundComponent } from './system/not-found/not-found.component';
import { UserRoleGuard } from './@shared/guards/userrole.guard';
import { SignupCompleteGuard } from './@shared/guards/signup-complete.guard';
import { SignupIncompleteGuard } from './@shared/guards/signup-incomplete.guard';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { SystemErrorComponent } from './system/error/error.component';

@Injectable()
export class TemplatePageTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title, private readonly translate: TranslateService) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(this.translate.instant('GENERAL.PAGE_TITLE', { name: _.get(window, ['gs_partner_name'], '') }) + ' - ' + this.translate.instant(title));
    } else {
      this.title.setTitle(this.translate.instant('GENERAL.PAGE_TITLE', { name: _.get(window, ['gs_partner_name'], '') }));
    }

    // just to satisfy A11Y tester - add a (hidden) h1 element matching the page title
    let h1 = document.body?.querySelector('h1');
    if (h1 && h1.classList.contains('autotitle')) {
      // we have h1
    } else if (h1) {
      // there already is a h1, don't touch
      h1 = null;
    } else {
      //create h1
      h1 = document.createElement('h1');
      h1.classList.add('autotitle');
      h1.setAttribute('style', 'display: block; width: 1px; height: 1px; opacity: 0; overflow: hidden; padding: 0; margin: 0; position: fixed; top: -100px;')

      document.body?.prepend(h1);
    }

    if (h1) {
      h1.textContent = this.title.getTitle()
    }


  }
}


export const appRoutes: Routes = [
  {
    path: 'Access',
    loadChildren: () => import('./auth/auth.routes').then(routes => routes.authRoutes),
    canActivate: [NoAuthGuard]
  },
  {
    path: 'Register',
    loadChildren: () => import('./modules/register/register.routes').then(m => m.registerRoutes),
    canActivate: [AuthGuard, SignupIncompleteGuard]
  },
  {
    path: '',
    loadChildren: () => import('./modules/modules.routes').then(m => m.moduleRoutes),
    data: {
      moduleMinUserRole: 'read' // using the user role guard here, will ensure the modules will not get initialized before the user/contract is loaded
    },
    canActivate: [AuthGuard, UserRoleGuard, SignupCompleteGuard]
  },
  {
    path: 'System/Error',
    component: SystemErrorComponent
  },
  {
    path: '**',
    component: NotFoundComponent,
    pathMatch: 'full'
  }
];

