import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-system-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
    imports: [TranslateModule]
})
export class SystemErrorComponent {
  constructor() { }

}
