import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  concatMap,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';

import * as SessionActions from './session.actions';
import { MiddlewareService } from './../../services/middleware.service';
import { globalFailure, noopAction } from '../common.actions';
import {
  GS_AUTH,
  GS_AUTH_LOCALSTORAGE_KEY,
  LoginWithOneTimeTokenResult,
} from './session.reducer';
import { Session } from './../../models/session.model';
import * as SessionSelectors from './../session/session.selectors';
import { Store } from '@ngrx/store';
import { ConfigService } from '@gridscale/ingrid/helper/services/config.service';
import { getConfig } from '../config/config.selectors';
import * as _ from 'lodash';
import { LocalstorageService } from '@gridscale/gs-services/localstorage.service';

@Injectable()
export class SessionEffects {
  constructor(
    private actions$: Actions,
    private middleware: MiddlewareService,
    private store: Store,
    private configService: ConfigService,
    private readonly localStorageService: LocalstorageService
  ) {}

  validSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.sessionValid),

      withLatestFrom(this.store.select(SessionSelectors.getSessionData)),
      concatMap(([_action, _session]) => {
        if (_session) {
          //Store Data
          return of(SessionActions.loadSessionSuccess({ data: _session }));
        } else {
          return of(noopAction());
        }
      })
    )
  );

  invalidSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.sessionInvalid),
      concatMap((_action) => {
        return of(SessionActions.logoutSuccess());
      })
    )
  );

  switchProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.switchProject),
      concatMap((_action) => {
        return this.middleware
          .get('/switchproject/' + _action.target_project_uuid)
          .pipe(
            map((result: any) =>
              SessionActions.switchProjectSuccess({
                target_project_uuid: _action.target_project_uuid,
                data: result.body ? result.body : {},
              })
            ),
            catchError((error) =>
              of(
                SessionActions.switchProjectFailure({
                  target_project_uuid: _action.target_project_uuid,
                  error,
                }),
                globalFailure({ error })
              )
            )
          );
      })
    );
  });

  switchContract$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.switchContract),
      concatMap((_action) => {
        return this.middleware
          .get('/switchproject/' + _action.target_project_uuid)
          .pipe(
            map((result: any) =>
              SessionActions.switchContractSuccess({
                target_project_uuid: _action.target_project_uuid,
                data: result.body ? result.body : {},
              })
            ),
            catchError((error) =>
              of(
                SessionActions.switchContractFailure({
                  target_project_uuid: _action.target_project_uuid,
                  error,
                }),
                globalFailure({ error })
              )
            )
          );
      })
    );
  });

  /**
   * on successful contract- or project switch, save the new data to the localstorage
   */
  onSwitchSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SessionActions.switchProjectSuccess,
        SessionActions.switchContractSuccess
      ),
      switchMap((_action) =>
        this.configService.ready$.pipe(
          filter((ready) => !!ready),
          map((ready) => _action)
        )
      ),
      map((_action) => {
        // Store Data first
        if (_action.data && _action.data.token) {
          this.localStorageService.setItem(
            GS_AUTH_LOCALSTORAGE_KEY,
            JSON.stringify({
              contract_uuid: _action.data.contract_uuid,
              project_uuid: _action.data.project_uuid,
              token: _action.data.token,
              user_uuid: _action.data.user_uuid,
              partner_uuid: this.configService.partnerUuid,
            } as GS_AUTH)
          );
        }
        return noopAction();
      })
    );
  });

  loginSuccess2$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SessionActions.loginSuccess,
        SessionActions.loginWithOneTimeTokenSuccess
      ),
      switchMap((_action) =>
        this.configService.ready$.pipe(
          filter((ready) => !!ready),
          map((ready) => _action)
        )
      ),
      map((_action) => {
        // Store Data first
        if (_action.data && _action.data.token) {
          this.localStorageService.setItem(
            GS_AUTH_LOCALSTORAGE_KEY,
            JSON.stringify({
              contract_uuid: _action.data.contract_uuid,
              project_uuid: _action.data.project_uuid,
              token: _action.data.token,
              user_uuid: _action.data.user_uuid,
              partner_uuid: this.configService.partnerUuid,
            } as GS_AUTH)
          );
        }

        return SessionActions.loadSessionSuccess({
          data: {
            contract_uuid: _action.data.contract_uuid,
            project_uuid: _action.data.project_uuid,
            token: _action.data.token,
            user_uuid: _action.data.user_uuid,
            partner_uuid: this.configService.partnerUuid,
          } as Session,
        });
      })
    );
  });
  LogoutSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SessionActions.logoutSuccess),
        tap((_action) => {
          this.localStorageService.removeItem(GS_AUTH_LOCALSTORAGE_KEY);
        })
      );
    },
    { dispatch: false }
  );

  loginWithOneTimeToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.loginWithOneTimeToken),
      concatMap((_action) => {
        return this.middleware
          .get('/one-time-token-storage/' + _action.token)
          .pipe(
            map((result: any) =>
              SessionActions.loginWithOneTimeTokenSuccess({
                data: {
                  ...(_.omit(result, ['api_token']) as unknown as Omit<
                    LoginWithOneTimeTokenResult,
                    'api_token'
                  >),
                  token: result?.api_token,
                },
              })
            ),
            catchError((error) =>
              of(SessionActions.loginWithOneTimeTokenFailure({ error }))
            )
          );
      })
    );
  });

  jumpToPartner$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.jumpToPartner),
      withLatestFrom(
        this.store.select(SessionSelectors.getSessionData),
        this.store.select(getConfig)
      ),
      concatMap(([_action, _session, _config]) => {
        return this.middleware
          .post('/one-time-token-storage', {
            api_token: _session.token,
            partner_uuid:
              _session.partner_uuid || _.get(_config, ['partner_uuid']),
            project_uuid: _session.project_uuid,
            contract_uuid: _session.contract_uuid,
            user_uuid: _session.user_uuid,
          })
          .pipe(
            map((result: any) => {
              if (result && result['one_time_token']) {
                this.localStorageService.removeItem(GS_AUTH_LOCALSTORAGE_KEY);

                //  window.location.href = '/Partner/?oneTimeToken=' + result['one_time_token'];
                window.location.href =
                  '/Partner/?oneTimeToken=' + result['one_time_token'];
              }

              return noopAction();
            }),
            catchError((error) => of(globalFailure({ error })))
          );
      })
    );
  });
}
